import InstructorAssignmentType from './assignmentType';
import './styles.css';

export default function InstructorAssignmentsList({ games, onGameDeletionConfirm }) {
  if (!games || !games.length) {
    return null;
  }

  const customOrder = [
    'Account Classification',
    'Balanced Equation',
    'Normal Balance | (debits & credits)',
    'The Profit'
  ];

  const groupedGames = games.reduce((acc, game) => {
    if (!acc[game.game_type]) {
      acc[game.game_type] = [];
    }
    acc[game.game_type].push(game);
    return acc;
  }, {});

  const sortedGameTypes = Object.keys(groupedGames).sort((a, b) => 
    customOrder.indexOf(a) - customOrder.indexOf(b)
  );

  return (
    <div className="instructor-assignments-list">
      {sortedGameTypes.map((gameType, index) => (
        <InstructorAssignmentType
          key={index}
          gameType={gameType}
          games={groupedGames[gameType]}
          onGameDeletionConfirm={onGameDeletionConfirm}
        />
      ))}
    </div>
  );
}
