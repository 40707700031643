import { Link, useOutlet, useLocation } from 'react-router-dom';

import './styles.css';
import Header from '../../game/header';

import { getPathSegments } from '../../../../utils';

const leftbarItems = [
  {
    title: 'Dashboard',
    url: '/instructor/dashboard',
    class: 'dashboard'
  },
  {
    title: 'Settings',
    url: '/instructor/settings/assignments',
    class: 'settings'
  },
  {
    title: 'Games',
    url: '/instructor/games',
    class: 'games'
  },
  {
    title: 'Insights',
    url: '/instructor/insights/',
    class: 'insights'
  },
];

export default function InstructorLayout() {
  const outlet = useOutlet();
  const location = useLocation();

  return (
    <div className="instructor-layout">
      <Header />
      <main>
        <div className="instructor-leftbar">
          <ul>
            {leftbarItems.map((leftbarItem, index) => {

              const isActive = (location.pathname === leftbarItem.url)
                || (getPathSegments(location.pathname).slice(0, 2).join('/') === getPathSegments(leftbarItem.url).slice(0, 2).join('/')) || (location.pathname.includes('/instructor/welcome') && leftbarItem.url === '/instructor/dashboard');
              
              return (
                <li key={index}>
                  <Link
                    to={leftbarItem.url}
                    className={`${leftbarItem.class} ${isActive ? 'active' : ''}`}
                    title={leftbarItem.title}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content">
          {outlet}
        </div>
      </main>
    </div>
  );
}
