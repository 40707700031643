import { useState, useEffect } from 'react';
import {
  accountColumnTitles,
  balancedEquationAccountsTexting
} from './data';

import InstructorAccountItemCheckbox from './accountCheckbox';
import InstructorAccountItemRadioButton from './accountRadioButton';
import Alert from '../../../alert';

import './styles.css';

export default function InstructorLevelItemsModal({
  scenarioLevelNumber,
  onCloseButtonClick,
  onSaveButtonClick,
  isButtonLoading,
  gameType,
  filterItems
}) {
  const getVisibleItemsByFilters = (selectedParent, selectedFilter) => {
    return filterItems[selectedParent][selectedFilter];
  };

  const defaultParent = Object.keys(filterItems)[0] || '';
  const defaultFilter = defaultParent ? Object.keys(filterItems[defaultParent])[0] : '';

  const [selectedFilterParent, setSelectedFilterParent] = useState(defaultParent);
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [accountItemsVisible, setAccountItemsVisible] = useState([]);
  const [accountItemsChecked, setAccountItemsChecked] = useState([]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    if (Object.keys(filterItems).length > 0) {
      const defaultParent = Object.keys(filterItems)[0];
      const defaultFilter = Object.keys(filterItems[defaultParent])[0] || '';
  
      setSelectedFilterParent(defaultParent);
      setSelectedFilter(defaultFilter);
  
      if (defaultParent && defaultFilter) {
        const visibleItems = getVisibleItemsByFilters(defaultParent, defaultFilter);
        setAccountItemsVisible(visibleItems);
  
        let preCheckedItems = [];
        if (gameType === 'Balanced Equation') {
          const preCheckedOrders =
            scenarioLevelNumber === 1
              ? [1, 2, 3]
              : scenarioLevelNumber === 2
              ? [4, 5, 6]
              : scenarioLevelNumber === 3
              ? [7, 8, 9]
              : [];
  
          preCheckedItems = Object.values(filterItems)
            .flatMap(childFilters => Object.values(childFilters).flat())
            .filter(item => preCheckedOrders.includes(item.order));
        } else {
          const corporateOrders = [
            1, 2, 4, 6, 7, 8, 12, 13, 14, 15, 22, 23, 24, 25, 28, 30, 31, 32, 33, 34, 36, 42,
          ];
          const corporateItems = filterItems['Entity Type']?.Corporate || [];
          preCheckedItems = corporateItems.filter(item => corporateOrders.includes(item.order));
  
          const noncorporateOrders = [
            1, 2, 3, 6, 8, 11, 12, 15, 23, 24, 25, 28, 29, 30, 31, 33, 36,
          ];
          const noncorporateItems = filterItems['Entity Type']?.Noncorporate || [];
          preCheckedItems = [
            ...preCheckedItems,
            ...noncorporateItems.filter(item => noncorporateOrders.includes(item.order)),
          ];
  
          const specialAccountsItems = Object.values(filterItems['Special Accounts'] || {}).flatMap(
            childFilters => childFilters
          );
          preCheckedItems = [...preCheckedItems, ...specialAccountsItems];
        }
  
        setAccountItemsChecked(preCheckedItems);
      }
    }
  }, [filterItems, scenarioLevelNumber]);
  
  

  const handleFilterChange = (name) => {
    let parentFilter = null;
  
    for (const [parentKey, childItems] of Object.entries(filterItems)) {
      if (parentKey === name || Object.keys(childItems).includes(name)) {
        parentFilter = parentKey;
        break;
      }
    }
  
    const isParent = parentFilter === name;
    const newSelectedFilter = isParent
      ? Object.keys(filterItems[parentFilter])[0] || name
      : name;
  
    setSelectedFilter(newSelectedFilter);
    setSelectedFilterParent(parentFilter);
  
    setAccountItemsVisible(filterItems[parentFilter]?.[newSelectedFilter] || []);
  };
  

  const handleAccountItemCheck = (name, isChecked) => {
    let updatedCheckedItems;

    if (isChecked) {
      const item = accountItemsVisible.find(item => item.text === name);
      if (item) {
        updatedCheckedItems = [...accountItemsChecked, item];
      }
    } else {
      updatedCheckedItems = accountItemsChecked.filter(item => item.text !== name);
    }

    setAccountItemsChecked(updatedCheckedItems);
  };

  const handleAccountItemsSaved = () => {
    onCloseButtonClick();
  };

  const handleSaveButtonClick = () => {
    const accountItemsVisibleIds = accountItemsVisible.map(item => item.id);
    const accountItemsCheckedVisible = accountItemsChecked.filter(item => accountItemsVisibleIds.includes(item.id));
    console.log('accountItemsCheckedVisible', accountItemsCheckedVisible);

    if (accountItemsCheckedVisible.length > 15) {
      setIsAlertVisible(true);
    } else {
      onSaveButtonClick(accountItemsCheckedVisible, selectedFilter, handleAccountItemsSaved);
    }
  };

  const handleConfirmAlertButtonClick = () => {
    setIsAlertVisible(false);
  };

  const selectAccountsHeadingPart = gameType === 'Balanced Equation'
    ? 'Selecting Transactions'
    : 'Select Accounts';
  
  const selectAccountsDescription =
    gameType === 'Balanced Equation'
      ? balancedEquationAccountsTexting[scenarioLevelNumber - 1] || balancedEquationAccountsTexting[2]
      : 'Max selection: 15 Accounts';

  return (
    <div className={`modal-overlay`}>
      <div className="modal instructor-level-items-modal">
        <h3>Level {scenarioLevelNumber} | {selectAccountsHeadingPart}</h3>
        <button className="close-button" onClick={onCloseButtonClick}></button>

        <p>{selectAccountsDescription}</p>

        <div className="instructor-level-items-filter-container">
          <div className="instructor-level-items-filter-column">
            <h4>Select Filter</h4>
            {Object.entries(filterItems).map(([parentKey, childItems], index) => (
              <div key={`filter-${index}`}>
                <InstructorAccountItemRadioButton
                  isChecked={selectedFilterParent === parentKey}
                  label={parentKey}
                  onCheck={() => handleFilterChange(parentKey)}
                />
                <div className="account-item-children">
                  {Object.keys(childItems).map((childKey, subIndex) => (
                      <InstructorAccountItemRadioButton
                        key={`child-${index}-${subIndex}`}
                        isChecked={selectedFilter === childKey}
                        label={childKey}
                        onCheck={() => handleFilterChange(childKey)}
                      />
                  ))}
                </div>
              </div>
            ))}
          </div>

          {accountColumnTitles[gameType].map((columnTitle, index) => {
            const relatedItems = accountItemsVisible?.filter((item) =>
              item.column === columnTitle
            );

            return (
              <div className="instructor-level-items-filter-column" key={`filter-column-${index}`}>
                <h4>{columnTitle}</h4>

                {relatedItems?.map((item, itemIndex) => (
                  <InstructorAccountItemCheckbox
                    key={`item-${index}-${itemIndex}`}
                    isChecked={accountItemsChecked.some(checkedItem => checkedItem.id === item.id)}
                    label={item.text}
                    onCheck={(event) =>
                      handleAccountItemCheck(item.text, event.target.checked)
                    }
                  />
                ))}
              </div>
            );
          })}
        </div>

        <button
          className={`button-blue save-account-items-selection button-save-assignment ${isButtonLoading ? 'loading' : ''}`}
          onClick={handleSaveButtonClick}
        >
          {!isButtonLoading && 'Save'}
        </button>
      </div>

      {
        isAlertVisible &&
          <Alert 
            type="error"
            title="Error | Maximum Selection Reached"
            text="You can select up to 15 accounts."
            confirmButtonText="Ok"
            onConfirmButtonClick={handleConfirmAlertButtonClick}
          />
      }
    </div>
  );
}
