
import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';

import InstructorSubPagesLayout from '../../../UI/instructor/subPagesLayout';
import InstructorAssignmentForm from '../../../UI/instructor/settings/assignments/form';

import { gameTypeMappings } from '../../../../vars';
import { formatScnearioLevelToFilterName } from '../../../../utils';

import { 
  navigation,
  gameCreateItemsQuestionsColumns,
  gameCreateItemsOptionsClassification,
  gameCreateItemsOptionsNormal
} from './data';

import {
  coursesQuery,
  gamesQuery,
  scenarioLevelsQuery,
  gamesItemsQuery,
  scenariosQuery,
  editGame,
  createScenarioLevels,
  createItemQuestions,
  createItemOptions,
} from './api';

import InstructorAssignmentSetLevels from '../../../UI/instructor/settings/assignments/setLevels';

export default function InstructorSettingsEditAssignments() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const coursesResponse = useQuery(coursesQuery()) || {};
  const courses = coursesResponse.data;

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const params = useParams();
  const gameId = Number(params.gameId);
  const [formData, setFormData] = useState({});
  const [scenarioId, setScenarioId] = useState(0);

  const [corporateScenarioId, setCorporateScenarioId] = useState(null);
  const [nonCorporateScenarioId, setNonCorporateScenarioId] = useState(null);

  const [entityTypeScenarioId, setEntityTypeScenarioId] = useState(null);
  const [specialAccountsScenarioId, setSpecialAccountsScenarioId] = useState(null);

  const [filterItems, setFilterItems] = useState({});

  useEffect(() => {
    if (games && gameId) {
      const currentGame = games.find((game) => game.id === gameId);
      if (currentGame) {
        setFormData({
          name: currentGame.name || '',
          description: currentGame.description || '',
          startTime: currentGame.start_time || '',
          endTime: currentGame.end_time || '',
          gameType: currentGame.game_type
        });

        setScenarioId(currentGame.scenario);
      }
    }
  }, [games, gameId]);
  
  const { data: scenarioLevels, refetch: refetchScenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));

  const { data: corporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: corporateScenarioId }));
  const { data: nonCorporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: nonCorporateScenarioId }));

  const { data: entityTypeScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: entityTypeScenarioId }));
  const { data: specialAccountsScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: specialAccountsScenarioId }));

  const { data: scenarios, refetch: refetchScenariosQuery } = useQuery(scenariosQuery());
  
  const gamesItemsResponse = useQuery(gamesItemsQuery()) || {};
  const gamesItems = gamesItemsResponse.data;
  
  const editGameMutation = useMutation({
    mutationFn: (data) => editGame(data)
  });

  const createScenarioLevelsMutation = useMutation({
    mutationFn: (data) => createScenarioLevels(data)
  });
  
  const createItemQuestionsMutation = useMutation({
    mutationFn: (data) => createItemQuestions(data)
  });
  
  const createItemOptionsMutation = useMutation({
    mutationFn: (data) => createItemOptions(data)
  });

  const handleEditGameSettings = async ({
    name,
    description,
    startTime,
    endTime
  }) => {
    setIsLoading(true);
    await editGameMutation.mutateAsync({
      id: gameId,
      name,
      description,
      startTime,
      endTime
    }).catch((error) => {
      console.error('Caught error with mutateAsync:', error);
    });

    setIsLoading(false);
  }

  const handleAddLevelButtonClick = () => {
    refetchScenariosQuery();
  }

  const gameTypeShortened = useMemo(() => {
    return (
      Object.entries(gameTypeMappings).find(([key]) => formData.gameType?.includes(key))?.[1] || ''
    );
  }, [formData.gameType]);

  useEffect(() => {
    if(scenarios) {
      if(gameTypeShortened === 'equation') {
        const corporateScenario = scenarios.find(item => item.name === 'be-corporate');
        const nonCorporateScenario = scenarios.find(item => item.name === 'be-noncorporate');
    
        setCorporateScenarioId(corporateScenario?.id || null);
        setNonCorporateScenarioId(nonCorporateScenario?.id || null);
      } else if (gameTypeShortened === 'classification' || gameTypeShortened === 'normal') {
        const entityTypeScenario = scenarios.find(item => item.name === 'entity-type');
        const specialAccountsScenario = scenarios.find(item => item.name === 'special-accounts');
    
        setEntityTypeScenarioId(entityTypeScenario?.id || null);
        setSpecialAccountsScenarioId(specialAccountsScenario?.id || null);
      }
    }
  }, [scenarios, gameTypeShortened]);
  
  useEffect(() => {
    if(corporateScenarioLevels?.length && nonCorporateScenarioLevels?.length) {
      const filterItems = {
        'Corporate': {
          ...corporateScenarioLevels.reduce((acc, { name, be_questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = be_questions.map(({ transaction_id, order, description }) => ({
              id: transaction_id,
              order,
              text: description,
              column: 'Transactions'
            }));
            return acc;
          }, {})
        },
        'Non-Corporate': {
          ...nonCorporateScenarioLevels.reduce((acc, { name, be_questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = be_questions.map(({ transaction_id, order, description }) => ({
              id: transaction_id,
              order,
              text: description,
              column: 'Transactions'
            }));
            return acc;
          }, {})
        },
      };

      setFilterItems(filterItems);
    }
  }, [corporateScenarioLevels, nonCorporateScenarioLevels]);
  
  useEffect(() => {
    if(entityTypeScenarioLevels?.length && specialAccountsScenarioLevels?.length) {
      const filterItems = {
        'Entity Type': {
          ...entityTypeScenarioLevels.reduce((acc, { name, questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = questions.map(({ id, order, account_item }) => {
              const relatedItemQuestion = gamesItems.find((gamesItem) => gamesItem.name === account_item.name);
              const relatedItemQuestionColumn = gameCreateItemsQuestionsColumns.find(column =>
                relatedItemQuestion.parents.some(parentId =>
                  gamesItems.some(item => item.id === parentId && item.name === column)
                )
              );

              return ({
                id,
                order,
                text: account_item.name,
                column: relatedItemQuestionColumn,
                accountItemId: account_item.id
              })
            });
            return acc;
          }, {}),
        },
        'Special Accounts': {
          ...specialAccountsScenarioLevels.reduce((acc, { name, questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = questions.map(({ id, order, account_item }) => {
              const relatedItemQuestion = gamesItems.find((gamesItem) => gamesItem.name === account_item.name);
              const relatedItemQuestionColumn = gameCreateItemsQuestionsColumns.find(column =>
                relatedItemQuestion.parents.some(parentId =>
                  gamesItems.some(item => item.id === parentId && item.name === column)
                )
              );

              return ({
                id,
                order,
                text: account_item.name,
                column: relatedItemQuestionColumn,
                accountItemId: account_item.id
              })
            });
            return acc;
          }, {}),
        },
      };
      
      setFilterItems(filterItems);
    }
  }, [entityTypeScenarioLevels, specialAccountsScenarioLevels]);

  const handleScenarioLevelAccontSelectSaveButtonClick = async (selectedAccountItems, selectedFilter, accountItemsSavedCallback) => {
    setIsLoading(true);
    const resultCreateScenarioLevels = await createScenarioLevelsMutation.mutateAsync({ 
      name: `${courses[0]?.name}${scenarioLevels.length + 1}`,
      levelNumber: scenarioLevels.length + 1,
      scenarios: [scenarioId]
    });
    
    if(resultCreateScenarioLevels?.id) {
      try {
        for (const [index, gameCreateItemQuestion] of selectedAccountItems.entries()) {
          let gamesItemQuestion;
          if(gameTypeShortened === 'equation') {
            gamesItemQuestion = gameCreateItemQuestion;
          } else if (gameTypeShortened === 'classification' || 'normal') {
            gamesItemQuestion = gamesItems.find((gamesItem) => gamesItem.id === gameCreateItemQuestion.accountItemId);
          }
          
          if(gamesItemQuestion) {
            await createItemQuestionsMutation.mutateAsync({
              gameType: gameTypeShortened,
              order: index + 1,
              accountItemId: gamesItemQuestion.id,
              scenarioLevelId: resultCreateScenarioLevels.id
            });
          }
        }          
      } catch (error) {
        console.log('Some mutations failed:', error);
      } finally {
        if(gameTypeShortened === 'classification' || gameTypeShortened === 'normal') {
          const gameCreateItemsOptions = gameTypeShortened === 'classification'
            ? gameCreateItemsOptionsClassification
            : gameCreateItemsOptionsNormal;
          
          try {
            for (const [index, gameCreateItemOption] of gameCreateItemsOptions.entries()) {
              const matchedGamesItemOption = gamesItems.find((gamesItem) => gamesItem.name === gameCreateItemOption);
              if(matchedGamesItemOption) {
                await createItemOptionsMutation.mutateAsync({
                  gameType: gameTypeShortened,
                  order: index + 1,
                  accountItemId: matchedGamesItemOption.id,
                  scenarioLevelId: resultCreateScenarioLevels.id
                });
              }
            }          
          } catch (error) {
            console.log('Some mutations failed:', error);
          }
        } else if(gameTypeShortened === 'equation') {
          const balanceEquationScenarioLevels = [...corporateScenarioLevels, ...nonCorporateScenarioLevels];
          const filterScenarioLevel = balanceEquationScenarioLevels.find(level => 
            level.name.toLowerCase().replace(/\s+/g, '-') === selectedFilter.toLowerCase().replace(/\s+/g, '-')
          );

          let filterScenarioOptions = [];
          switch (resultCreateScenarioLevels.level) {
            case 1:
              filterScenarioOptions = filterScenarioLevel.be_options.filter(option => option.order >= 1 && option.order <= 5);
              break;
            case 2:
              filterScenarioOptions = filterScenarioLevel.be_options.filter(option => 
                (option.order >= 1 && option.order <= 5) || option.account_item.account === true
              );
              break;
            case 3:
              filterScenarioOptions = filterScenarioLevel.be_options.filter(option => 
                (option.order >= 1 && option.order <= 7) || option.account_item.account === true
              );
              break;
            default:
              filterScenarioOptions = filterScenarioLevel.be_options.filter(option => 
                (option.order >= 1 && option.order <= 7) || option.account_item.account === true
              );
              break;
          }

          try {
            for (const [index, gameCreateItemOption] of filterScenarioOptions.entries()) {
              await createItemOptionsMutation.mutateAsync({
                gameType: gameTypeShortened,
                order: index + 1,
                accountItemId: gameCreateItemOption.account_item.id,
                scenarioLevelId: resultCreateScenarioLevels.id
              });
            }          
          } catch (error) {
            console.log('Some mutations failed:', error);
          }
        }
      }
    }
    
    refetchScenarioLevels();
    accountItemsSavedCallback();
    setIsLoading(false);
  }

  const handleCloseButtonClick = () => {
    navigate('/instructor/settings/assignments');
  }

  return (
    <InstructorSubPagesLayout
      navigation={navigation}
      contentClassName="adjustment-content-container"
      showPagination={false}
      secondButtonName="Close"
      onSecondButtonClick={handleCloseButtonClick}
    >
      <InstructorAssignmentForm
        onSave={handleEditGameSettings}
        isButtonLoading={isLoading}
        initialValues={formData}
      />

      <InstructorAssignmentSetLevels 
        gameType={formData.gameType}
        scenarioLevels={scenarioLevels}
        gamesItems={gamesItems}
        onScenarioLevelAccountSelectSave={handleScenarioLevelAccontSelectSaveButtonClick}
        isButtonLoading={isLoading}
        onAddLevelClick={handleAddLevelButtonClick}
        filterItems={filterItems}
      />
    </InstructorSubPagesLayout>
  );
}
