import { useQuery, useMutation } from '@tanstack/react-query';

import InstructorSubPagesLayout from '../../../UI/instructor/subPagesLayout';
import InstructorAssignmentsList from '../../../UI/instructor/settings/assignments/assignmentsList';
import InstructorAddAssignmentsRow from '../../../UI/instructor/settings/assignments/addRow';

import { gamesQuery, deleteGame } from './api';

import { navigation } from './data';

export default function InstructorSettingsAssignments() {
  const { data: games, refetch: refetchGames } = useQuery(gamesQuery());

  const deleteGameMutation = useMutation({
    mutationFn: (data) => deleteGame(data)
  });

  const handleGameDelete = async (gameId, gameDeletedCallback) => {
    await deleteGameMutation.mutate({gameId});
    gameDeletedCallback();
    refetchGames();
    await refetchGames();
  }

  return (
    <InstructorSubPagesLayout
      navigation={navigation}
      contentClassName="adjustment-content-container"
    >
      <InstructorAddAssignmentsRow />
      <InstructorAssignmentsList 
        games={games}
        onGameDeletionConfirm={handleGameDelete}
      />
    </InstructorSubPagesLayout>
  );
}
