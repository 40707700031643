export const navigation = [
  {
    title: 'Assignment',
    url: '/instructor/settings/assignments'
  }
];

export const gameCreateItemsQuestionsColumns = [
  'Assets',
  'Liabilities',
  'Equity',
  'Revenues',
  'Expenses'
];

export const gameCreateItemsOptionsClassification = [
  'Balance Sheet',
  'Income Statement'
];

export const gameCreateItemsOptionsNormal = [
  'Debit',
  'Credit'
];