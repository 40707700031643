import './styles.css';

import gameLogoClassification from './images/game-logo-classification.png';
import gameLogoBalancedEquation from './images/game-logo-balanced-equation.png';
import gameLogoNormalBalance from './images/game-logo-normal-balance.png';

import { gameTypeMappings } from '../../../../../vars';
import InstructorAssignmentItem from './assignmentItem';

export default function InstructorAssignmentType({ gameType, games, onGameDeletionConfirm }) {
  const gameTypeImages = {
    'Account Classification': gameLogoClassification,
    'Balanced Equation': gameLogoBalancedEquation,
    'Normal Balance | (debits & credits)': gameLogoNormalBalance,
  };

  const imageSrc = gameTypeImages[gameType];

  const gameTypeTitles = {
    'Account Classification': 'Classification',
    'Normal Balance | (debits & credits)': 'Normal Balance',
  };

  const gameTypeShortened = Object.entries(gameTypeMappings).find(([key]) => gameType?.includes(key))?.[1] || '';

  return (
    <div className={`instructor-assignment-type ${gameTypeShortened}`}>
      <div className="instructor-assignment-type-heading">
        {imageSrc && <img src={imageSrc} alt={gameType} className="game-logo" />}
        <h3>{gameTypeTitles[gameType] || gameType}</h3>
      </div>
      {
        games.map((game, index) => (
          <InstructorAssignmentItem 
            key={index}
            game={game}
            onGameDeletionConfirm={onGameDeletionConfirm}
          />
        ))
      }
    </div>
  );
}
