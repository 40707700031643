import { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime';
import moment from "moment";

import "react-datetime/css/react-datetime.css";
import './styles.css';
import Alert from '../../../alert';

import { games } from './data';

export default function InstructorAssignmentForm({onSave, gamesCount = 0, isButtonLoading, initialValues}) {
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [name, setName] = useState(initialValues?.name || '');

  useEffect(() => {
    if(gamesCount && !initialValues) {
      setName(`Assignment #${gamesCount + 1}`);
    }
  }, [gamesCount, initialValues]);

  const [description, setDescription] = useState(initialValues?.description || '');
  const [dateFrom, setDateFrom] = useState(initialValues ? '' : moment().add(1, 'days'));
  const [dateTo, setDateTo] = useState(initialValues ? '' : moment().add(30, 'days'));
  const [selectedGame, setSelectedGame] = useState(initialValues?.gameType || '');

  useEffect(() => {
    if (
      initialValues?.name
      && initialValues?.description
      && initialValues?.startTime
      && initialValues?.endTime
      && initialValues?.gameType
    ) {
      setName(initialValues.name);
      setDescription(initialValues.description);
      setDateFrom(moment(initialValues.startTime));
      setDateTo(moment(initialValues.endTime));
      setSelectedGame(initialValues.gameType);
    }
  }, [initialValues, gamesCount]);
  
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertText, setAlertText] = useState('');

  const handleGameButtonClick = (game) => {
    setSelectedGame(game);
    setIsFormChanged(true);
    if(game === 'Account Classification') {
      setDescription('In this exercise, accounts are falling and you have to classify them quickly and accurately to build mastery.');
    } else if(game === 'Normal Balance | (debits & credits)') {
      setDescription('In this exercise, you have to sort accounts to their correct normal balances.');      
    } else if(game === 'Balanced Equation') {
      setDescription('In this exercise, you\'ll start by deciding how transactions affect the three balance sheet elements, the decisions progress to identifying the impacted accounts and culminate in deciding which accounts should be debited or credited.');    
    }
  }
  const handleNameChange = (e) => {
    setName(e.target.value);

    if(selectedGame) {
      setIsFormChanged(true);
    }
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);

    if(selectedGame) {
      setIsFormChanged(true);
    }
  };
  const handleDateFromChange = (date) => {
    const selectedDate = moment(date);
  
    if (!selectedDate.isValid()) {
      console.warn('Invalid date selected for start time');
      return;
    }
  
    if (selectedDate.isBefore(moment())) {
      setAlertTitle('Error | In the Past');
      setAlertText('The start time cannot be earlier than the current time.');
      setIsAlertVisible(true);
      setDateFrom(moment().add(1, 'days'));
      return;
    }
  
    if (selectedDate.isSameOrAfter(dateTo)) {
      setAlertTitle('Error | Start Time > End Time');
      setAlertText('The start time must be earlier than the end time.');
      setIsAlertVisible(true);
      setDateFrom(moment().add(1, 'days'));
      return;
    }

    if(selectedGame) {
      setIsFormChanged(true);
    }
  
    setDateFrom(selectedDate);
  };  
  const handleDateToChange = (date) => {
    const selectedDate = moment(date);
  
    if (!selectedDate.isValid()) {
      console.warn('Invalid date selected for end time');
      return;
    }
  
    if (selectedDate.isSameOrBefore(moment())) {
      setAlertTitle('Error | End Time < Current Time');
      setAlertText('The end time must be later than the current time.');
      setIsAlertVisible(true);
      setDateTo(moment().add(30, 'days'));
      return;
    }
  
    if (selectedDate.isSameOrBefore(dateFrom)) {
      setAlertTitle('Error | Start Time > End Time');
      setAlertText('The start time must be earlier than the end time.');
      setIsAlertVisible(true);
      setDateTo(moment().add(30, 'days'));
      return;
    }

    if(selectedGame) {
      setIsFormChanged(true);
    }
  
    setDateTo(selectedDate);
  };
  

  const handleConfirmAlertButtonClick = () => {
    setIsAlertVisible(false);
  }

  const handleSaveButtonClick = () => {
    if(!name) {
      setAlertTitle('Error | Name can\'t be blank');
      setAlertText('The assignment name is required. Please enter a valid name before proceeding.');
      setIsAlertVisible(true);
      return;
    }

    onSave({
      name,
      description,
      startTime: dateFrom,
      endTime: dateTo,
      gameType: selectedGame
    });
  }

  return (
    <div className="instructor-add-assignment-form">
      {
        isAlertVisible &&
          <Alert 
            type="error"
            title={alertTitle}
            text={alertText}
            confirmButtonText="Done"
            onConfirmButtonClick={handleConfirmAlertButtonClick}
          />
      }

      <hr/>

      <div className="instructor-input-container">
        <label className="full-width">Select an Activity</label>
        
        <div className="assignment-game-buttons">
          {
            games.map((game, index) => (
              <button
                key={index}
                onClick={() => handleGameButtonClick(game.name)} 
                className={`button-game ${selectedGame === game.name ? 'active' : ''}`}
                disabled={!game.active || initialValues}
              >
                {game.name}
              </button>
            ))
          }
        </div>
      </div>

      <hr/>

      <div className="instructor-input-container">
        <label htmlFor="assignment-name">Name</label>
        <input 
          id="assignment-name"
          type="text"
          value={name}
          onChange={handleNameChange}
          // onKeyUp={handleKeyPress}
        />
      </div>
      
      <div className="instructor-input-container">
        <label htmlFor="assignment-description">Description</label>
        <textarea 
          id="assignment-description"
          value={description}
          onChange={handleDescriptionChange}
          // onKeyUp={handleKeyPress}
        />
      </div>
      
      <div className="instructor-input-container">
        <label>Submission Window</label>
        <div className="instructor-date-selector-wrapper date-selector-from">
          <label htmlFor="assignment-from" className="instructor-date-selector-label">From</label>
          <DateTimePicker
            dateFormat="ddd, MMM DD YYYY,"
            timeFormat="hh:mm a"
            inputProps={{
              id: "assignment-from",
              className: "instructor-datetime-selector"
            }}
            value={dateFrom}
            onChange={handleDateFromChange}
          />
        </div>
        <div className="instructor-date-selector-wrapper date-selector-to">
          <label htmlFor="assignment-to" className="instructor-date-selector-label">To</label>
          <DateTimePicker
            dateFormat="ddd, MMM DD YYYY,"
            timeFormat="hh:mm a"
            inputProps={{
              id: "assignment-to",
              className: "instructor-datetime-selector"
            }}
            value={dateTo}
            onChange={handleDateToChange}
          />
        </div>
        
        <button 
          className={`button-blue button-save-assignment ${isButtonLoading ? 'loading' : ''}`}
          disabled={!isFormChanged}
          onClick={handleSaveButtonClick}
        >
          {!isButtonLoading && 'Save'}
        </button>
      </div>

      <hr/>
    </div>
  );
}
