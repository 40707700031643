import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import './styles.css';

import Alert from '../../../alert';

export default function InstructorAssignmentItem({ game, onGameDeletionConfirm }) {
  const navigate = useNavigate();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleEditButtonClick = () => {
    navigate(`/instructor/settings/assignments/edit/${game.id}`);
  }

  const handleDeleteAssignmentButtonClick = () => {
    setIsAlertVisible(true);
  }

  const handleConfirmAlertButtonClick = () => {
    onGameDeletionConfirm(game.id, handleCloseAlert);
  }

  const handleCloseAlert = () => {
    setIsAlertVisible(false);
  }

  function formatDate(endTime) {
    return moment(endTime).format('MMM D [at] ha');
  }

  return (
    <div className="instructor-assignment-item">
      <button className="edit-assignment" onClick={handleEditButtonClick}></button>
      <div className="instructor-assignment-item-info">
        <div className="instructor-assignment-item-info-heading">
          <h4>{game.name}</h4>
          <button className="delete-assignment" onClick={handleDeleteAssignmentButtonClick}></button>
        </div>
        <div className="instructor-assignment-item-info-footer">
          <p><span>Open </span>{formatDate(game.start_time)} <span>| Close</span> {formatDate(game.end_time)}</p>
        </div>
      </div>

      {
        isAlertVisible &&
          <Alert 
            type="warning"
            title={`Are you sure you want to delete ${game.name}?`}
            confirmButtonText="Yes"
            cancelButtonText="No"
            onCancelButtonClick={handleCloseAlert}
            onConfirmButtonClick={handleConfirmAlertButtonClick}
          />
      }
    </div>
  );
}
