import { useNavigate } from 'react-router-dom';

import InstructorSubPagesLayout from '../../UI/instructor/subPagesLayout';

import './styles.css';

import { navigation } from './data'; 

import AccountingConceptualFramework from './images/accounting-conceptual-framework.png';

export default function InstructorWelcomeMasteringFundamentals() {
  const navigate = useNavigate();

  const handlePrevButtonCLick = () => {
    navigate('/instructor/welcome/welcome-to-accounting-games');
  }

  const handleNextButtonCLick = () => {
    navigate('/instructor/welcome/how-scoring-works');
  }

  return (
    <InstructorSubPagesLayout 
      onFirstButtonClick={handlePrevButtonCLick}
      onSecondButtonClick={handleNextButtonCLick}
      navigation={navigation}
    >
      <h1>Games Overview</h1>
      <h2>Mastering Fundamentals</h2>
      <p>Our games are bite-sized, playful exercises that are centered around the conceptual framework of accounting and designed to boost engagement and reinforce learning.</p>
      <img className="accounting-framework-image" src={AccountingConceptualFramework} alt="Accounting Conceptual Framework" />
    </InstructorSubPagesLayout>
  );
}
