import { useState } from 'react';

import "react-datetime/css/react-datetime.css";
import './styles.css';
import InstructorLevelItemsModal from './levelItemsModal';

import IconPlus from './images/icon-plus.svg';
import InstructorAssignmentLevelSavedAccounts from './levelSavedAccounts';

export default function InstructorAssignmentSetLevels({
  gameType,
  scenarioLevels,
  gamesItems,
  onScenarioLevelAccountSelectSave,
  isButtonLoading,
  onAddLevelClick,
  filterItems
}) {
  const [activeTab, setActiveTab] = useState(0);
  
  const [isModalShown, setIsModalShown] = useState(false);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }

  const handleAddLevelButtonClick = () => {
    onAddLevelClick();
    setIsModalShown(true);
  }

  const handleModalCloseButtonClick = () => {
    setIsModalShown(false);
  }

  const handleModalButtonSaveClick = () => {
    setIsModalShown(false);
  }

  return (
    <div className="instructor-set-levels-container">
      <h3>Set Levels</h3>
      <div className="instructor-assignment-levels">
        <div className="instructor-assignment-level-tabs">
          {
            scenarioLevels?.map((scenarioLevel, index) => (
              <button 
                key={index}
                className={`assignment-tab-header ${index === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                Level {scenarioLevel.level}
              </button>
            ))
          }
          {
            scenarioLevels?.length < 4 &&
              <button 
                className="assignment-tab-add-level"
                onClick={handleAddLevelButtonClick}
              >
                Add Level <img src={IconPlus} alt="Add"/>
              </button>
          }
        </div>
        <div className="instructor-assignment-tab-content">
          {
            gamesItems && gameType && scenarioLevels && scenarioLevels.length > 0 &&
              <InstructorAssignmentLevelSavedAccounts
                gameType={gameType}
                scenarioLevels={scenarioLevels}
                gamesItems={gamesItems}
                activeScenarioLevel={activeTab}
              />
          }
        </div>
      </div>


      {isModalShown && (
        <InstructorLevelItemsModal
          scenarioLevelNumber={scenarioLevels?.length + 1 || 0}
          onCloseButtonClick={handleModalCloseButtonClick}
          onSaveButtonClick={onScenarioLevelAccountSelectSave}
          onSave={handleModalButtonSaveClick}
          isButtonLoading={isButtonLoading}
          gameType={gameType}
          filterItems={filterItems}
        />
      )}
    </div>
  );
}
